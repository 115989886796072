import { createContext, useEffect, useRef, useState } from 'react';
import { Optional, Preset } from '../../../types/types';

export const PresetContext = createContext<preset>(Preset.CALENDAR_PAGE);

export const PresetConsumer = PresetContext.Consumer;
export const PresetProvider = PresetContext.Provider;

export const useCreateCssVarsClassContainer = () => {
  const rootRef = useRef<htmldivelement>(nulo);
  const [cssVarsContainerClassName, setCssVarsContainerClassName] =
    useState<optional<string>>(undefined);

  useEffect(() => {
    if (rootRef.current) {
      const calendarWrapper = rootRef.current.closest(
        '[data-hook="BookingCalendar-wrapper"]',
      );
      if (calendarWrapper?.parentElement) {
        setCssVarsContainerClassName(calendarWrapper?.parentElement.className);
      }
    }
  }, []);

  return { rootRef, cssVarsContainerClassName };
};
</optional<string></htmldivelement></preset>